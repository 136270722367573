import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// import { PiHandshakeDuotone } from "react-icons/pi";



import x_icon_d from './../media/icons/x_icon_d.png'
import tg_icon_d from './../media/icons/tg_icon_d.png'
import ig_icon_d from './../media/icons/ig_icon_d.png'
import check_icon from './../media/icons/check.png'
import st_icon_1 from './../media/icons/st-icon-1.png'
import st_icon_2 from './../media/icons/st-icon-2.png'
import safe_check_icon from './../media/icons/safe-check.png'
import flame_icon from './../media/icons/flame.png'
import globe_icon from './../media/icons/globe.png'
import coin_icon from './../media/icons/coin-ub.png'

import nemo_3 from './../media/img/nemo_3.png'
import nemo_2 from './../media/img/nemo_2.png'
import nemo_text from './../media/img/nemo_text.png'
import nemo_fight from './../media/newart/nemo-fighting.png'
import nemo_happy from './../media/newart/happy-nemo.png'
import phantom_logo from './../media/img/phantom_logo.png'
import sol_logo from './../media/img/sol_logo.png'
import ray_logo from './../media/img/ray_logo.jpg'
import ocu_logo from './../media/img/ocu-logo.png'

import rl_nb from './../media/img/rl-nb.png'
import rl_nbg_1 from './../media/img/rl-nbg-1.png'
import rl_bg_4 from './../media/img/rl-bg-4.jpg'
import rl_nbg_4 from './../media/img/rl-nbg-4.png'


import { ReactComponent as BscLogo } from './../media/img/bscscan-logo.svg';

import x_icon from './../media/icons/x-icon.png'
import twitter_icon from './../media/icons/twitter-icon.jpg'
import tg from './../media/icons/tg-icon.png'


import tg_icon from './../media/icons/tg.png'
import twitter from './../media/icons/twitter.png'
import mail_icon from './../media/icons/mail-icon.png'

const Home = () => {


    const [htwCount, setHtwcount] = useState(0)



    return ( 
        <div className="">

            <section id="home" className="home">
                <div className="home-cont">
                    <div className="flex row align-center justify-center wrap">
                        <div className="home-text">
                            <div className="home-head-subhead" data-aos="fade-right" data-aos-duration="2000">
                                <h2><span>ROY:</span> THE FLUFFY BOSS</h2>
                                <h3>🚀 Are you ready to meet the newest sensation in the crypto jungle? Say hello to ROY, the purr-fect blend of innovation, safety, and excitement on the Solana Blockchain! 🌐</h3>
                            </div>
                            
                            <div className="home-ca-cont">
                               <h3>Contract Address</h3>
                               <div className="home-ca-box">
                                    <p>2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9</p>
                               </div>
                            </div>
                            
                            <a href="https://jup.ag/swap/SOL-2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9" target="_blank" className='btn-1'>
                                Buy Token Now
                            </a>
                        </div>

                        <div className="home-img">
                            <div className="flex column overflow-y-hidden">
                                <p className="home-img-by-text">Buy Now</p>
                                <img src={rl_nbg_1} className="home-text-logo-ant" alt="" data-aos="zoom-in" data-aos-duration="2000" />
                                <div className="flex row align-center justify-center home-img-line-box">
                                    <div></div>
                                    <p>ROY</p>
                                    {/* <img src={nemo_text} alt="" /> */}
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about" id="about">
                <div className="main-frame">
                    <div className="flex row wrap align-center">
                        <div className="about-text" data-aos="fade-right" data-aos-duration="2000">
                            {/* <h2>About Popeye</h2> */}
                            <h3>🌟🐾 Introducing ROY "The Fluffy Boss"</h3>
                            <p>
                                🐾 ROY's Mission:<br />
                                As the new kitty boss of crypto, ROY is dedicated to creating a safe and secure environment for all traders. With our rugproof 
                                status and rigorous security measures, you can trade with confidence and peace of mind. <br /><br />
                                Don't miss your chance to be part of the ROY revolution! Join The ROY MOB today and experience crypto like never before. 
                                Your journey to the top starts with ROY! 🚀🐾 #FluffyBossRevolution #SafeCryptoTrading
                            </p>

                            <a href="https://t.me/ROY_Cat" className="about-btn flex row gap-15 justify-center align-center">
                               <span>Join The Community</span> 
                               <img src={tg_icon} alt="" />
                            </a>
                        </div>
                        <div className="about-img" data-aos="fade-left1" data-aos-duration="2000">
                            {/* <video src={pop_logo_intro} muted autoPlay loop /> */}
                            <img src={rl_nbg_4} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tokenomics" id="tokenomics">
                <div className="main-frame">                    
                    <div className="tk-header overflow-y-hidden" data-aos="fade-right" data-aos-duration="2000">
                        <h2>Total Supply <span>9.000 B</span></h2>
                        <p data-aos="fade-up" data-aos-duration="1000">
                            $ROY is a meme token with Utility.
                        </p>
                    </div>
                    <div className="row token-table overflow-y-hidden">
                        <div className="flex-100">
                            <center>
                                <table>
                                    <tr>
                                        <th>Specification</th>
                                        <th>Detail</th>
                                    </tr>
                                    <tr>
                                        <td>Token Name:</td>
                                        <td>ROY Token</td>
                                    </tr>
                                    <tr>
                                        <td>Ticker Symbol:</td>
                                        <td>$RV2</td>
                                    </tr>
                                    <tr>
                                        <td>Blockchain:</td>
                                        <td>Solana</td>
                                    </tr>
                                    <tr>
                                        <td>Contract Type:</td>
                                        <td>SOL</td>
                                    </tr>
                                    <tr>
                                        <td>Decimals:</td>
                                        <td>9</td>
                                    </tr>
                                    <tr>
                                        <td>Total Supply:</td>
                                        <td>9.000.000.000</td>
                                    </tr>
                                </table>
                            </center>
                        </div>
                    </div>
                    <div className="row tax-box">
                        <div className="tax-heading"><h2>$ROY Token tax</h2></div>
                    </div>

                    <div className="row token-table overflow-y-hidden">
                        <div className="flex-100">
                            <center>
                                <table>
                                    <tr>
                                        <th colSpan="3">Roy token tax structure</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>BUY</td>
                                        <td>SELL</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Liquidity</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Auto Burn</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Marketing</td>
                                        <td>4%</td>
                                        <td>4%</td>
                                    </tr> */}
                                    <tr>
                                        <td>TOTAL TAX</td>
                                        <td>0%</td>
                                        <td>0%</td>
                                    </tr>
                                </table>
                            </center>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roadmap" id="roadmap">
                <div className="main-frame">
                    <div className="roadmap-header overflow-y-hidden" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Learn about our path</h2>
                        <h3>The Roadmap of $ROY</h3>
                    </div>

                    <div className="roadmap-item-cont grid grid-column-3 grid-column-gap-25 grid-row-gap-10 overflow-y-hidden">
                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 1</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Create Smart Contract</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch $ROY Token</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch Website</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>SOL Trending Campaign</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 2</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Launch "Search for Roy" Campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>500-1000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>2 Billion Tokens Burned Post-Launch for Scarcity and Value 💥</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>On-going Prizes</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Contract Renounced and RugProof 🛡️</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Marketing</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 3</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>1500 - 2000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Liquidity Burned! 🔥🔥</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Cutting-edge Create-to-Earn Token Utility 🎬💰</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Massive meme marketing campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>NFTs and Staking Available Soon 🚀💎</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>More Thrilling Surprises Await!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why" id="why">
                <div className="main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Why to be a Part of the $ROY Movement</h2>
                        <h3>Features of $ROY</h3>
                    </div>

                    <div className="grid grid-column-4 gap-20 overflow-y-hidden">
                        <div className="why-item" data-aos="fade-right" data-aos-duration="2000">
                            <div className="flex justify-center">
                                <div className="flex justify-center align-center wic-box">
                                   <img src={safe_check_icon} alt="" /> 
                                </div>
                            </div>
                            <h2>Safety</h2>
                            <h3>RugProof for Safety and Security 🛡️</h3>
                        </div>
                        <div className="why-item" data-aos="fade-up" data-aos-duration="2000">
                            <div className="flex justify-center">
                                <div className="flex justify-center align-center wic-box">
                                   <img src={flame_icon} alt="" />  
                                </div>
                            </div>
                            <h2>Burn</h2>
                            <h3>💥 Strategic Token Burn for Value Appreciation</h3>
                        </div>
                        <div className="why-item" data-aos="fade-up" data-aos-duration="2000">
                            <div className="flex justify-center">
                                <div className="flex justify-center align-center wic-box">
                                   <img src={coin_icon} alt="" />  
                                </div>
                            </div>
                            <h2>Create 2 Earn</h2>
                            <h3>💰 Lucrative Create-to-Earn Opportunities</h3>
                        </div>
                        <div className="why-item" data-aos="fade-left" data-aos-duration="2000">
                            <div className="flex justify-center">
                                <div className="flex justify-center align-center wic-box">
                                   <img src={globe_icon} alt="" />  
                                </div>
                            </div>
                            <h2>Innovation</h2>
                            <h3>🌐 Continuous Innovation and Growth</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="htb" id="htb">
                <div className="main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Be a Part of the $ROY Movement</h2>
                        <h3>How to buy $ROY</h3>
                    </div>
                    <div className="grid grid-column-2 row grid-column-gap-40 grid-row-gap-20 overflow-y-hidden">


                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={phantom_logo} alt="" />
                                </div>
                                <h4>Download Phantom</h4>
                                <p>
                                    Create a <a href="" target="_blank">Phantom</a> Wallet using either a desktop computer or an iOS/Android mobile device. Purchase $SOL on CEX like Coinbase, Binance, KuCoin, etc.
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={sol_logo} alt="" />
                                </div>
                                <h4>Secure your SOL</h4>
                                <p>
                                    Now all you've got to do is buy your SOL in the PHANTOM APP, or, alternatively you can use an exchange and deposit using your wallet address.
                                </p>
                            </div>
                        </div>
                        
                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={ray_logo} className="htb-ray" alt="" />
                                </div>
                                <h4>Swap/Buy $ROY</h4>
                                <p>
                                    Go to Raydium/Jupiter. Insert the contract adress: 2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9. And ROY will Show up. You then can make the swap.
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={rl_nb} alt="" />
                                </div>
                                <h4>Add to Your Wallet</h4>
                                <p>
                                    Now you're all set! Welcome aboard the next rocket to the moon, just import the contract address to view you're holdings and you can track your earnings.
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* <section id="roy-runner" className="roy-runner">
                <div className="main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h3>ROYRUNNER</h3>
                    </div>

                    <div className="roy-runner-cont">
                        <iframe src="https://roy-runner.netlify.app" name="ROYRUNNER" scrolling="no"width="100%" height="100%"></iframe>
                    </div>


                </div>
            </section> */}

            {/* <section className="eco" id="eco">
                <div className="wider-frame-percentage">
                    <div className="eco-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Ecosystem Tokens</h2>
                    </div>

                    <div className="eco-container">
                        <div className="flex row wrap align-center">
                            <div className="eco-text" data-aos="fade-right" data-aos-duration="2000">
                                <h2>Peki Inu</h2>
                                <p className="eco-pgh">
                                    Peki Inu Token's mission is to harness the unparalleled dedication and creativity of its 
                                    predecessor communities to create a decentralized ecosystem that offers more than just 
                                    a digital currency. It aims to provide real utility. Already 10% burn, and we have an auto burn ca 
                                </p>
                                <div className="flex row justify-center align-center gap-5 copy-address-box">
                                    <img src={copy_icon} alt="" />
                                    <p>0x760539FF254A88B925fd940C8Bc01069cf76069b</p>
                                </div>
                                <div className="flex row gap-15">
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                                        target="_blank" className='btn-1'
                                    >
                                        Buy Peki Inu
                                    </a>
                                    <a href="https://top100token.com/address/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" className='btn-sp'>
                                        View Chart
                                    </a>
                                </div>
                                <div className="eco-line"></div>
                                <p className="eco-list">
                                    <span>Token name</span>: Peki Inu <br />
                                    <span>Token Ticker</span>: PekiInu <br />
                                    <span>Total supply</span>: 200.000.000.000.000.000 <br />
                                    <span>Total Burned</span>: 20% <br />
                                </p>
                            </div>
                            <div className="eco-img" data-aos="fade-left" data-aos-duration="2000">
                                <img src={peki_rocket} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="partners" id="partners">
                <div className="partners-header" data-aos="fade-up" data-aos-duration="2000">
                    <h2>Our Partners</h2>
                </div>

                <div className="mid-frame">
                    <div className="flex row wrap overflow-y-hidden">
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                            target="_blank" className="partners-item flex justify-center" data-aos="fade-right" data-aos-duration="2000"
                        >
                            <img src={ps_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xa93d6925f1e1243f4c06f8e1c678629c8365b8f8?t=1713098809201" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-up" data-aos-duration="2000"
                        >
                            <img src={dt_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://bscscan.com/token/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-left" data-aos-duration="2000"
                        >
                            <BscLogo className="partners-item-img" />
                        </a>
                    </div>
                </div>
            </section> */}

            {/* <section className="coming-soon" id="coming-soon">
                <div className="cs-main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h3>Coming Soon</h3>
                    </div>
                    <div className="cs-item-grid grid row grid-column-2 grid-column-gap-20 grid-row-gap-20">
                        <div className="cs-item" data-aos="fade-right" data-aos-duration="2000">
                            <div className="cs-top flex row justify-center align-center gap-10">
                                <img src={nemo_happy} className="cs-nemo" alt="" />
                                <h4>X</h4>
                                <img src={sol_logo} className="cs-swap-img" alt="" />
                            </div>
                            <h2>Nemo Sol Swap</h2>
                            <p>
                                Exciting news! Our new Swap feature is launching soon. You'll be able to swap tokens effortlessly within our 
                                platform, providing seamless and efficient transactions. Stay connected for more updates as we roll out this 
                                game-changing feature to enhance your trading experience! 🔄💰
                            </p>
                        </div>

                        <div className="cs-item" data-aos="fade-left" data-aos-duration="2000">
                            <div className="cs-top flex row justify-center align-center gap-10">
                                <img src={nemo_happy} className="cs-nemo" alt="" />
                                <h4>X</h4>
                                <img src={st_icon_2} className="cs-swap-img" alt="" />
                            </div>
                            <h2>Staking</h2>
                            <p>
                                Prepare to unlock new rewards! Our highly anticipated Staking feature is on its way. Soon, you'll be able to stake your tokens and 
                                earn generous returns, enhancing your investment and supporting our ecosystem. Stay tuned for more details and get ready to make the most of your holdings! 🚀💎
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="join" id="join">
                <div className="main-frame">
                    <div className="flex">
                        <div className="flex-100 join-bg-1">
                            <div className="join-bg-2">
                                <div className="">
                                    
                                        <div className="flex row gap-10 j-icons" data-aos="fade-up" data-aos-duration="2000">
                                            <a href="https://t.me/ROY_Cat" target="_blank">
                                                <img src={tg_icon} alt="" />
                                            </a>
                                            <a href="https://x.com/roycatcrypto?s=21" target="_blank">
                                                <img src={twitter} alt="" />
                                            </a>
                                            <a href="" target="_blank">
                                                <img src={mail_icon} alt="" />
                                            </a>
                                        </div>
                                        <div className="join-text ">
                                            <h3 data-aos="fade-right" data-aos-duration="2000">Join our Community</h3>
                                            <p data-aos="fade-left" data-aos-duration="2000">Stay connected to $ROY</p>
                                            <a target="_blank" href="https://t.me/ROY_Cat" className="btn-1">Join Telegram</a>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer" id="footer">
                <div className="main-container">
                    <div className="flex row justify-space-between align-center footer-row-box">
                        <div className="flex row gap-45" data-aos="fade-right" data-aos-duration="2000">
                            <a href="https://x.com/roycatcrypto?s=21" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={x_icon_d} alt="" />
                            </a>
                            <a href="https://t.me/ROY_Cat" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={tg_icon_d} alt="" />
                            </a>
                            {/* <a href="" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={ig_icon_d} alt="" />
                            </a> */}
                        </div>

                        <a href="https://jup.ag/swap/SOL-2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9" 
                            target="_blank" className="btn-1"
                            data-aos="fade-left" data-aos-duration="2000"
                        >
                            Click To ROY In
                        </a>
                    </div>
                    <hr />
                    <p className="copyright">Roy copyright 2024 - All Rights Reserved</p>
                </div>
            </section>

        </div>
    );
}
 
export default Home;