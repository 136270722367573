import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo_no_bg from './../media/img/rl-nb.png'
import cancel_icon from './../media/icons/close.png'
import nav_icon from './../media/icons/menu.png'


const NavBar = () => {

    const pathlocation = useLocation()

    const windowscrollvalue = window.scrollY
    const [showmobilenav, setshowmobilenav] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            console.log(window.innerWidth)
        };

        const handleScroll = () => {
            // console.log(window.scrollY)
            setshowmobilenav(null)
        }

        window.addEventListener('resize', handleResize);

        window.addEventListener('scroll', handleScroll);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll)
        };
    }, []);

    const handleMobilenav = () => {
        if(!showmobilenav) {
            setshowmobilenav(true)
        } else {
            setshowmobilenav(false)
        }
    }



    return ( 
        <>
            <header>
                <a href="">
                    <img src={logo_no_bg} className='logo' alt="" />
                </a>

                {windowWidth > 991 ?
                    <ul>
                        <li className={`nav-link ${pathlocation.pathname === '/' ? 'active' : ''}`}><Link to='/'>Home</Link></li>
                        <li className='nav-link'><a href='#tokenomics'>Tokenomics</a></li>
                        <li className='nav-link'><a href='#roadmap'>Roadmap</a></li>
                        <li className={`nav-link ${pathlocation.pathname === '/merchs' ? 'active' : ''}`}><Link to='/merchs'>Merch</Link></li>
                        {/* <li className='join-btn'><Link to='#'>Join Now</Link></li> */}
                        <li>
                            <Link to='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9&fixed=in' target='_blank' className='gs-btn'>
                                Buy Now
                            </Link>
                        </li>
                    </ul>
                : null}

                {windowWidth < 991 ? 
                    <div className='nav-icon' onClick={handleMobilenav}>
                        <img src={showmobilenav ? cancel_icon : nav_icon} alt="" />
                    </div>
                : null}
            </header>

            <div className={`mobile-nav ${showmobilenav ? 'active' : ''}`}>
                <div className="mobile-nav-item">
                    <Link to='/' className='open-sans_f' onClick={handleMobilenav}>Home</Link>
                </div>
                <div className="mobile-nav-item">
                    <a href='#about' className='open-sans_f' onClick={handleMobilenav}>About</a>
                </div>
                <div className="mobile-nav-item">
                    <a href='#tokenomics' className='open-sans_f' onClick={handleMobilenav}>Tokenomics</a>
                </div>
                <div className="mobile-nav-item">
                    <a href='#roadmap' className='open-sans_f' onClick={handleMobilenav}>Roadmap</a>
                </div>
                <div className="mobile-nav-item">
                    <Link to='/merchs' className='open-sans_f' onClick={handleMobilenav}>Merch</Link>
                </div>
                <div className="mobile-nav-item">
                    <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9&fixed=in' target='_blank' className='open-sans_f' onClick={handleMobilenav}>
                        Buy Now
                    </a>
                </div>
            </div>

        </>
    );
}
 
export default NavBar;