import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// import { PiHandshakeDuotone } from "react-icons/pi";



import x_icon_d from './../media/icons/x_icon_d.png'
import tg_icon_d from './../media/icons/tg_icon_d.png'
import ig_icon_d from './../media/icons/ig_icon_d.png'
import check_icon from './../media/icons/check.png'
import st_icon_1 from './../media/icons/st-icon-1.png'
import st_icon_2 from './../media/icons/st-icon-2.png'
import safe_check_icon from './../media/icons/safe-check.png'
import flame_icon from './../media/icons/flame.png'
import globe_icon from './../media/icons/globe.png'
import coin_icon from './../media/icons/coin-ub.png'

import nemo_3 from './../media/img/nemo_3.png'
import nemo_2 from './../media/img/nemo_2.png'
import nemo_text from './../media/img/nemo_text.png'
import nemo_fight from './../media/newart/nemo-fighting.png'
import nemo_happy from './../media/newart/happy-nemo.png'
import phantom_logo from './../media/img/phantom_logo.png'
import sol_logo from './../media/img/sol_logo.png'
import ray_logo from './../media/img/ray_logo.jpg'
import ocu_logo from './../media/img/ocu-logo.png'

import rl_nb from './../media/img/rl-nb.png'
import rl_nbg_1 from './../media/img/rl-nbg-1.png'
import rl_bg_4 from './../media/img/rl-bg-4.jpg'
import rl_nbg_4 from './../media/img/rl-nbg-4.png'


import { ReactComponent as BscLogo } from './../media/img/bscscan-logo.svg';

import x_icon from './../media/icons/x-icon.png'
import twitter_icon from './../media/icons/twitter-icon.jpg'
import tg from './../media/icons/tg-icon.png'


import tg_icon from './../media/icons/tg.png'
import twitter from './../media/icons/twitter.png'
import mail_icon from './../media/icons/mail-icon.png'

const MerchTab = () => {


    const [htwCount, setHtwcount] = useState(0)



    return ( 
        <div className="">

            <section className="merch-sec flex align-center justify-center" id="merch-sec">
                <div className="merch-sec-frame">
                    <h1>Roy Merchandise</h1>
                    <h2>Coming Soon</h2>
                    <a href="https://t.me/ROY_Cat" target="_blank" className="btn-1">Stay Tuned</a>
                </div>
            </section>

            <section className="footer" id="footer">
                <div className="main-container">
                    <div className="flex row justify-space-between align-center footer-row-box">
                        <div className="flex row gap-45" data-aos="fade-right" data-aos-duration="2000">
                            <a href="https://x.com/roycatcrypto?s=21" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={x_icon_d} alt="" />
                            </a>
                            <a href="https://t.me/ROY_Cat" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={tg_icon_d} alt="" />
                            </a>
                            {/* <a href="" target="_blank" className="flex align-center justify-center ft-row-icon-box">
                                <img src={ig_icon_d} alt="" />
                            </a> */}
                        </div>

                        <a href="https://jup.ag/swap/SOL-2MeaYDPd7eRM4h86XuY9HNnfMyzJdHxuvah6GTyy8fH9" 
                            target="_blank" className="btn-1"
                            data-aos="fade-left" data-aos-duration="2000"
                        >
                            Click To ROY In
                        </a>
                    </div>
                    <hr />
                    <p className="copyright">Roy copyright 2024 - All Rights Reserved</p>
                </div>
            </section>

        </div>
    );
}
 
export default MerchTab;